import "@reach/dialog/styles.css";
import "@reach/menu-button/styles.css";
import "@reach/tooltip/styles.css";
import * as React from "react";
import { useAsync } from "react-async-hook";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { SDK } from "./sdk";
import { SDKContext } from "./SDKContext";
import { UserContext } from "./UserContext";
import * as auth from "./shared/auth";
import { formatDocumentTitle } from "./shared/formatDocumentTitle";
import DocumentTitle from "./components/lib/DocumentTitle";
import Layout from "./components/Layout";
import DefaultErrorBoundary from "./components/DefaultErrorBoundary";
import FullPageLoading from "./components/FullPageLoading";
import GenericErrorMessage from "./components/GenericErrorMessage";

const Login = React.lazy(() => import("./pages/Login/Login"));
const Landing = React.lazy(() => import("./pages/Landing/Landing"));
const Home = React.lazy(() => import("./pages/Home/Home"));
const DISMetrics = React.lazy(() => import("./pages/DISMetrics/DISMetrics"));
const EmailInterface = React.lazy(() => import("./pages/EmailInterface/EmailInterface"));
const OrganizationApiKeys = React.lazy(() => import("./pages/OrganizationApiKeys/OrganizationApiKeys"));
const ClientCreate = React.lazy(() =>
  import("./pages/ClientCreate/ClientCreate")
);
const Clients = React.lazy(() => import("./pages/Clients/Clients"));
const ClientMetrics = React.lazy(() =>
  import("./pages/ClientMetrics/ClientMetrics")
);
const Threats = React.lazy(() => import("./pages/Threats/Threats"));
const ASNs = React.lazy(() => import("./pages/ASNs/ASNs"));
const AttackSources = React.lazy(() =>
  import("./pages/AttackSources/AttackSources")
);
const OrganizationCreate = React.lazy(() =>
  import("./pages/OrganizationCreate/OrganizationCreate")
);
const OrganizationUpdate = React.lazy(() =>
  import("./pages/OrganizationUpdate/OrganizationUpdate")
);
const Organization = React.lazy(() =>
  import("./pages/Organization/Organization")
);
const OrganizationIpGroups = React.lazy(() =>
  import("./pages/OrganizationIpGroups/OrganizationIpGroups")
);
const OrganizationUsers = React.lazy(() =>
  import("./pages/OrganizationUsers/OrganizationUsers")
);
const OrganizationRoles = React.lazy(() =>
  import("./pages/OrganizationRoles/OrganizationRoles")
);
const OrganizationReports = React.lazy(() =>
  import("./pages/OrganizationReports/OrganizationReports")
);
const SelectOrganizationReports = React.lazy(() =>
  import("./pages/OrganizationReports/SelectOrganizationReports")
);
const OrganizationSubmittedTracebackReports = React.lazy(() =>
  import("./pages/OrganizationSubmittedTracebackReports/OrganizationSubmittedTracebackReports")
);
const SelectOrganizationSubmittedTracebackReports = React.lazy(() =>
  import("./pages/OrganizationSubmittedTracebackReports/SelectOrganizationSubmittedTracebackReports")
);
const OrganizationTracebackReports = React.lazy(() =>
  import("./pages/OrganizationTracebackReports/OrganizationTracebackReports")
);
const SelectOrganizationTracebackReports = React.lazy(() =>
  import("./pages/OrganizationTracebackReports/SelectOrganizationTracebackReports")
);
const OrganizationSubmittedTracebackAsnMetrics = React.lazy(() =>
  import("./pages/OrganizationSubmittedTracebackAsnMetrics/OrganizationSubmittedTracebackAsnMetrics")
);
const SelectOrganizationSubmittedTracebackAsnMetrics = React.lazy(() =>
  import("./pages/OrganizationSubmittedTracebackAsnMetrics/SelectOrganizationSubmittedTracebackAsnMetrics")
);
const OrganizationTracebackAsnMetrics = React.lazy(() =>
  import("./pages/OrganizationTracebackAsnMetrics/OrganizationTracebackAsnMetrics")
);
const SelectOrganizationTracebackAsnMetrics = React.lazy(() =>
  import("./pages/OrganizationTracebackAsnMetrics/SelectOrganizationTracebackAsnMetrics")
);
const OrganizationSubmittedTracebackInterfaceMetrics = React.lazy(() =>
  import("./pages/OrganizationSubmittedTracebackInterfaceMetrics/OrganizationSubmittedTracebackInterfaceMetrics")
);
const SelectOrganizationSubmittedTracebackInterfaceMetrics = React.lazy(() =>
  import("./pages/OrganizationSubmittedTracebackInterfaceMetrics/SelectOrganizationSubmittedTracebackInterfaceMetrics")
);
const OrganizationTracebackInterfaceMetrics = React.lazy(() =>
  import("./pages/OrganizationTracebackInterfaceMetrics/OrganizationTracebackInterfaceMetrics")
);
const SelectOrganizationTracebackInterfaceMetrics = React.lazy(() =>
  import("./pages/OrganizationTracebackInterfaceMetrics/SelectOrganizationTracebackInterfaceMetrics")
);
const EventLogger = React.lazy(() =>
  import("./pages/EventLogger/EventLogger")
);
const SelectEventLogger = React.lazy(() =>
  import("./pages/EventLogger/SelectEventLogger")
);
const Profile = React.lazy(() => import("./pages/User/Profile"));
const PasswordReset = React.lazy(() => import("./pages/User/PasswordReset"));
const CompletePasswordReset = React.lazy(() =>
  import("./pages/User/CompletePasswordReset")
);
const RoleCreate = React.lazy(() => import("./pages/RoleCreate/RoleCreate"));
const NotFound = React.lazy(() => import("./pages/404/404"));

export default function App() {
  const sdk = new SDK({
    baseUrl: process.env.REACT_APP_BASE_API_URL as string,
  });
  const token = auth.getToken();
  if (token != null) {
    sdk.__setHeaders((x: any) => ({ ...x, "x-api-token": token }));
  }
  return (
    <DefaultErrorBoundary>
      <SDKContext.Provider value={{ sdk }}>
        <BrowserRouter>
          <React.Suspense fallback={<FullPageLoading />}>
            <Switch>
              <Route exact path="/">
                {!auth.isAuthenticated() ? (
                  <Landing />
                ) : (
                  <Redirect to="/home" />
                )}
              </Route>
              <RedirectIfAuthenticatedRoute exact path="/login">
                <DocumentTitle>{formatDocumentTitle("Login")}</DocumentTitle>
                <Login />
              </RedirectIfAuthenticatedRoute>
              <Route exact path="/password_reset">
                <DocumentTitle>
                  {formatDocumentTitle("Reset Password")}
                </DocumentTitle>
                <PasswordReset />
              </Route>
              <Route exact path="/complete-reset-password">
                <DocumentTitle>
                  {formatDocumentTitle("Complete Password Reset")}
                </DocumentTitle>
                <CompletePasswordReset />
              </Route>
              <PrivateRoute exact path="/home">
                <DocumentTitle>
                  {formatDocumentTitle("Dashboard")}
                </DocumentTitle>
                <Layout headerHeading="Dashboard">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <Home />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/dis-metrics">
                <DocumentTitle>
                  {formatDocumentTitle("DIS Metrics")}
                </DocumentTitle>
                <Layout headerHeading="DIS Metrics">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <DISMetrics />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/email-interface">
                <DocumentTitle>
                  {formatDocumentTitle("Email Interface")}
                </DocumentTitle>
                <Layout headerHeading="Email Interface">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <EmailInterface />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/api-keys">
                <DocumentTitle>
                  {formatDocumentTitle("Organization API Keys")}
                </DocumentTitle>
                <Layout headerHeading="Organization API Keys">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationApiKeys />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/clients/create">
                <DocumentTitle>
                  {formatDocumentTitle("Provision New Client")}
                </DocumentTitle>
                <Layout headerHeading="Provision New Client">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <ClientCreate />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/clients">
                <DocumentTitle>
                  {formatDocumentTitle("DIS Clients")}
                </DocumentTitle>
                <Layout headerHeading="DIS Clients">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <Clients />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/client-metrics">
                <DocumentTitle>
                  {formatDocumentTitle("DIS Client Metrics")}
                </DocumentTitle>
                <Layout headerHeading="DIS Client Metrics">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <ClientMetrics />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/threats">
                <DocumentTitle>{formatDocumentTitle("Threats")}</DocumentTitle>
                <Layout headerHeading="Threats">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <Threats />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/asns">
                <DocumentTitle>{formatDocumentTitle("ASNs")}</DocumentTitle>
                <Layout headerHeading="ASNs">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <ASNs />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/threats/:uuid/attack-sources">
                <DocumentTitle>
                  {formatDocumentTitle("Attack Source (IPs)")}
                </DocumentTitle>
                <Layout headerHeading="Attack Source (IPs)">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <AttackSources />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/create">
                <DocumentTitle>
                  {formatDocumentTitle("Create Organization")}
                </DocumentTitle>
                <Layout headerHeading="Create Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationCreate />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/update">
                <DocumentTitle>
                  {formatDocumentTitle("Update Organization")}
                </DocumentTitle>
                <Layout headerHeading="Update Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationUpdate />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <Organization />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/ip-groups">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationIpGroups />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/users">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationUsers />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/roles">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationRoles />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/report">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationReports />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/select-report">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <SelectOrganizationReports />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/submitted-traceback-report">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationSubmittedTracebackReports />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/select-submitted-traceback-report">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <SelectOrganizationSubmittedTracebackReports />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/traceback-report">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationTracebackReports />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/select-traceback-report">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <SelectOrganizationTracebackReports />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/submitted-traceback-asn-metrics">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationSubmittedTracebackAsnMetrics />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/select-submitted-traceback-asn-metrics">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <SelectOrganizationSubmittedTracebackAsnMetrics />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/traceback-asn-metrics">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationTracebackAsnMetrics />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/select-traceback-asn-metrics">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <SelectOrganizationTracebackAsnMetrics />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/submitted-traceback-interface-metrics">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationSubmittedTracebackInterfaceMetrics />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/select-submitted-traceback-interface-metrics">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <SelectOrganizationSubmittedTracebackInterfaceMetrics />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/traceback-interface-metrics">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <OrganizationTracebackInterfaceMetrics />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/organizations/:uuid/select-traceback-interface-metrics">
                <DocumentTitle>
                  {formatDocumentTitle("Organization")}
                </DocumentTitle>
                <Layout headerHeading="Organization">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <SelectOrganizationTracebackInterfaceMetrics />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/event-logger">
                <DocumentTitle>
                  {formatDocumentTitle("Event Logger")}
                </DocumentTitle>
                <Layout headerHeading="Event Logger">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <EventLogger />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/select-event-logger">
                <DocumentTitle>
                  {formatDocumentTitle("Select Event Logger")}
                </DocumentTitle>
                <Layout headerHeading="Select Event Logger">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <SelectEventLogger />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/profile">
                <DocumentTitle>{formatDocumentTitle("Profile")}</DocumentTitle>
                <Layout headerHeading="Profile">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <Profile />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/roles/create">
                <DocumentTitle>
                  {formatDocumentTitle("Create Role")}
                </DocumentTitle>
                <Layout headerHeading="Create Role">
                  <React.Suspense fallback={<FullPageLoading />}>
                    <RoleCreate />
                  </React.Suspense>
                </Layout>
              </PrivateRoute>
              <Route>
                <DocumentTitle>
                  {formatDocumentTitle("Page Not Found")}
                </DocumentTitle>
                <NotFound />
              </Route>
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </SDKContext.Provider>
    </DefaultErrorBoundary>
  );
}

function RedirectIfAuthenticatedRoute(props: RouteProps) {
  const { children, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.isAuthenticated()) {
          return children;
        }
        const searchParams = new URLSearchParams(props.location.search);
        const redirectKey = "to";
        const redirectLocation = searchParams.get(redirectKey) || "/";
        return <Redirect to={redirectLocation} />;
      }}
    />
  );
}

function PrivateRoute(props: RouteProps) {
  const { children, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => {
        return auth.isAuthenticated() ? (
          <User>
            {(user: any) => {
              const hasNoOrganizations = user.organizationList.length === 0;
              const redirectLocation = `/organizations/create`;
              if (
                hasNoOrganizations &&
                rest.location?.pathname !== redirectLocation
              ) {
                return <Redirect to={redirectLocation} />;
              }
              return (
                <UserContext.Provider value={{ user }}>
                  {children}
                </UserContext.Provider>
              );
            }}
          </User>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: new URLSearchParams({
                to: props.location.search
                  ? props.location.pathname + props.location.search
                  : props.location.pathname,
              }).toString(),
            }}
          />
        );
      }}
    />
  );
}

function User(props: { children: any }) {
  const { sdk } = React.useContext(SDKContext);
  const result = useAsync(
    () =>
      sdk.getMe().then((result) => {
        if (!result.error) {
          const organizationList = result.data.organizationList;
          // If no organization is selected, attempt to select one
          if (
            organizationList != null &&
            organizationList.length > 0 &&
            !organizationList.some((x) => !!x.isSelected)
          ) {
            const indexToSelect = 0;
            const organizationUuidToSelect =
              organizationList[indexToSelect].uuid;
            return sdk
              .updateOrganization(
                { uuid: organizationUuidToSelect },
                { isSelected: true }
              )
              .then(
                () => {
                  window.location.reload();
                  return result;
                },
                () => result
              );
          }
          return result;
        }
        return result;
      }),
    []
  );

  if (result.loading) {
    return <FullPageLoading />;
  }
  if (result.error) {
    return <GenericErrorMessage />;
  }
  if (result.result === undefined) {
    return null;
  }
  if (result.result.error) {
    return <GenericErrorMessage />;
  }

  const data = result.result.data;

  return props.children(data);
}
